#root {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--primary-bg-color);
}

a, a:visited, a:active, a:hover{
  color: inherit;
  text-decoration: none;
  outline: 0;
}
